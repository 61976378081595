<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>资源管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/knowledge_2' }">知识扩展</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
			<div class="padding-b-sm">
				<el-form :model="form"  ref="form1">
					<el-form-item label="标题" prop="title" :rules="[{ required: true, message: '请输入标题' , trigger: 'blur'}]">
						<el-input style="width: 18.75rem;" v-model="form.title" placeholder="标题"></el-input>
						<el-button class="margin-l-sm" size="mini" type="primary" @click="addTab" >插入1页</el-button>
						<el-button class="margin-l-sm" size="mini" type="danger" @click="removeTab" >移除1页</el-button>
					</el-form-item>
				</el-form>
			</div>
            <div>
                <el-tabs  v-model="currentTab" type="card" @tab-remove="removeTab" @tab-click="selectTab">
                    <el-tab-pane
                            v-for="item in editableTabs"
                            :key="item.name"
                            :label="item.title"
                            :name="item.name"
                    >
                    <div>
                        <el-form :model="form.pages[currentTab]" label-width="100px" ref="form" class="demo-ruleForm">
							<div>
								<el-form-item prop="content" :rules="[{ required: true, message: '请上传图片', trigger: 'blur' }]">
									<editCover :uploadPath="uploadPath" @success="onSuccess" :pic="imageUrl + form.pages[currentTab].content"></editCover>
								</el-form-item>
								<el-form-item label="解析：" prop="analyse" :rules="[{ required: true, message: '解析必须', trigger: 'blur' }]">
									<el-input v-model="form.pages[currentTab].analyse"
											  type="textarea"
											  :rows="10"
											  :maxlength="1000"
											  :show-word-limit="true"
											  placeholder="请输入解析"></el-input>
								</el-form-item>
							</div>
                        </el-form>
                    </div>
                    </el-tab-pane>
                </el-tabs>

                <el-button class="margin-l-sm" size="mini" type="primary" @click="validateForm()" >保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
	import { config } from '../../../js/config'
	import editCover from '../../../components/editCover'
    export default {
        name: "add",
		components: { editCover },
        data() {
            return {
				uploadPath: 'knowledge',
				imageUrl:config.imageUrl,
                currentTab:'0',
				type: [
					{
						value: 1,
						label: '好词'
					},
					{
						value: 2,
						label: '好句'
					}
				],
                editableTabs: [],
                form:{}
            }
        },
        methods: {
			...mapActions('knowledge',['getKnowledge','addKnowledge']),
            //添加选项
            removeOption(index){
                this.form.pages[this.currentTab].splice(index, 1)
            },
			// 表单提交前
			validateForm(){
				this.$refs['form1'].validate(async valid => {
					if (!valid) {
						return 
					} 
				});
				this.$refs.form[this.currentTab].validate(async valid => {
				  if (!valid) {
					return 
				  } 
				  this.submit()
				})
			},
            //提交表单保存
            async submit(){
               const {res_info, data } = await this.addKnowledge(this.form)
			   if(res_info !== 'ok') {
				   this.$message.error('保存失败!')
				   return
			   }
			   this.$message.success('保存成功!')
			   this.$router.push('/knowledge_2')
            },
            selectTab(tab,event){
				this.currentTab = tab.index
            },
           //添加
           addTab() {
           	let index = this.editableTabs.length
               let newTabName = index
               this.editableTabs.push({
                   title: '知识扩展'+(newTabName + 1),
                   name: newTabName + '',
               });
               this.form.pages.push({
           		"type":0,
           		"content_type":2,
           		"content":"",
           		"analyse":""
				});
               this.currentTab = newTabName + ''
			   this.currentIndex = Number(this.currentTab)
           },
           //移除
           removeTab() {
			if(this.editableTabs.length === 1)
			{
			   this.$message.error('至少保存一页标注')
			   return
			}
		    let tabs = this.editableTabs;
		    let activeName = (this.editableTabs.length - 1) + ''
			var vm = this
			vm.form.pages.splice(activeName, 1)
			vm.currentTab = '0'
			vm.editableTabs = tabs.filter(tab => tab.name !== activeName)
           },
		   // 图片上传
		   onSuccess(path) {
		   	this.form.pages[this.currentTab].content = path
		   },
        },
		async mounted() {
			var id = this.$route.params.id
			const {res_info,data} = await this.getKnowledge({id:id})
			if(res_info !== 'ok') return 
			this.form = data
			var totalTab = String(this.form.pages.length - 1??0)
			this.currentTab = totalTab
			var that = this
			that.form.pages.forEach(function(item,index){
				that.editableTabs[index] = {
					'title': '知识扩展' + (index+1),
					'name': ''+ index
				}
			})
		}
    }
</script>
